import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Wait for DOMContentLoaded to ensure the container exists
document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('chatbot');
  if (container) {
    const root = ReactDOM.createRoot(container);
    root.render(
      // <React.StrictMode>
      <App />
      // </React.StrictMode>
    );
  } else {
    console.error("Chatbot container not found. Ensure <div id='chatbot'></div> exists in the DOM.");
  }
});

reportWebVitals();
