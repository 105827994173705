import styled from "styled-components";

export const VoiceProgressRun = ({ progress }) => {
    return (
      <ProgressContainer>
        <ProgressRun progress={progress} />
      </ProgressContainer>
    );
  };

  const ProgressContainer = styled.div`
      width: 100%;
      height: 40px;
      background-color: #8c8c8c;
      border-radius: 5px;
      overflow: hidden;
    `;
  
    const ProgressRun = styled.div`
      height: 100%;
      background: linear-gradient(
        to right,
        rgb(140, 140, 140),
        rgb(140, 140, 140),
        rgb(123, 123, 123)
      );
      width: ${(props) => props.progress}%;
      transition: width 0.5s ease;
    `;