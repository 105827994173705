import "./App.css";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { ReactComponent as IconBot } from "./assets/iconbot.svg";
import { ReactComponent as IconBotAvatar } from "./assets/bot.svg";
import { ReactComponent as IconMiMx } from "./assets/mi-mx.svg";
import { ReactComponent as IconClose } from "./assets/close.svg";
import { ReactComponent as IconBar } from "./assets/bar.svg";
import { ReactComponent as IconVolumn } from "./assets/volumn.svg";
import { ReactComponent as IconMic } from "./assets/mic.svg";
import { ReactComponent as IconSend } from "./assets/send.svg";
import { ReactComponent as IconDisSend } from "./assets/dis-send.svg";
import { ReactComponent as IconCs } from "./assets/cs.svg";
import { ReactComponent as IconDf } from "./assets/df.svg";
import { ReactComponent as IconDrag } from "./assets/drag.svg";
import { ReactComponent as IconDXBOT } from "./assets/DXBOT.svg";
import { ReactComponent as IconLike } from "./assets/icon_like.svg";
import { ReactComponent as IconDisLike } from "./assets/icon_dislike.svg";
import { ReactComponent as IconDefaultHistory } from "./assets/default_history.svg";
import { useForm } from "react-hook-form";
import Draggable from "react-draggable";
import { VoiceProgressRun } from "./VoiceProgressRun";

const socket = io("wss://service.llmviet.one");

function App() {
  const [userId, setUserId] = useState(-2);
  const [botId, setBotId] = useState(1);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const hasJoined = useRef(false);
  const messagesEndRef = useRef(null);
  const [readingMessageIndex, setReadingMessageIndex] = useState(null);
  const [questionSuggest, setQuestionSuggest] = useState([]);
  const listRef = useRef(null);
  const [openBarOption, setOpenBarOption] = useState(false);
  const [showQuestionSuggest, setShowQuestionSuggest] = useState(true);
  const [miniSize, setMiniSize] = useState(true);
  const [isVoice, setIsVoice] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const transcriptTemp = useRef("");
  const [htmlContent, setHtmlContent] = useState("");
  const iframeRef = useRef(null);
  const [showChat, setShowChat] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [readyChat, setReadyChat] = useState(false);
  const [showSend, setShowSend] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://be.admin.llmviet.one/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: data.username,
            password: data.password,
            workSpace: "plugin",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setBotId(data?.data?.botId);
        setUserId(data?.data?.userId);
        setShowLogin(false);
        setReadyChat(true);
        handleCreateNewChat();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowChat = () => {
    setShowChat(!showChat);
  };
  const handleSize = () => {
    setMiniSize(!miniSize);
  };

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = "vi-VN";
      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        transcriptTemp.current = transcript;
        setMessage(transcript);
        setIsListening(false);
      };
      recognitionRef.current.onend = () => {
        stopListening();
      };
      recognitionRef.current.onerror = (event) => {
        stopListening();
      };
    } else {
      console.log("Web Speech API is not supported by this browser.");
    }
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to the server with ID:", socket.id);
    });

    socket.on("user_joined1", (data) => {
      const joinInfo = {
        conversation_id: data?.conversation_id,
        consultant_name: data?.consultant_name,
        user_id: data?.user_id,
        message_histories: data?.message_histories,
        type: data?.type,
      };
      setConversationId(joinInfo.conversation_id);
      if (joinInfo.type && joinInfo.type === "join") {
        const join_message = {
          message: `Nhân viên tư vấn đã tham gia cuộc trò chuyện.`,
          type: "join",
        };
        setMessages((prevMessages) => [...prevMessages, join_message]);
      }
    });

    socket.on("receive_message1", (data) => {
      if (data?.question_suggest) {
        setQuestionSuggest(data.question_suggest);
      } else {
        setShowQuestionSuggest(false);
      }
      setMessages((prevMessages) => [...prevMessages, data]);
      setIsLoading(false);
      setIsWaiting(false);
    });

    socket.on("send_message_response", (data) => {
      if (data.success && data.bot_response) {
        setIsLoading(true);
      }
    });

    socket.on("user_left1", (data) => {
      const join_message = {
        message: `Nhân viên tư vấn đã rời khỏi cuộc trò chuyện.`,
        type: "join",
      };
      setMessages((prevMessages) => [...prevMessages, join_message]);
    });

    socket.on("create_chat_success", (data) => {
      setConversationId(data?.conversation_id);
    });

    return () => {
      socket.off("connect");
      socket.off("user_joined1");
      socket.off("create_chat_success");
      socket.off("receive_message1");
      socket.off("user_left1");
    };
  }, [conversationId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // useEffect(() => {
  //   if (!hasJoined.current) {
  //     handleJoin();
  //     hasJoined.current = true;
  //   }
  // }, []);

  useEffect(() => {
    if (iframeRef.current && htmlContent) {
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(htmlContent);
      iframeDocument.close();
    }
  }, [htmlContent]);
  // useEffect(() => {
  //   window.addEventListener("message", (event) => {
  //     console.log(event.data);
  //     const formData = JSON.parse(event.data);
  //     if (formData) {
  //       setHtmlContent("");
  //     }
  //   });
  // }, []);
  // const handleJoin = () => {
  //   if (conversationId) {
  //     setUserId(1);
  //     socket.emit("join1", {
  //       token: conversationId,
  //     });
  //   } else {
  //     socket.emit("create_chat", { user_id: userId, bot_id: botId });
  //   }
  // };

  const handleSendMessage = async (messageInput) => {
    setIsWaiting(true);
    if (isVoice) {
      await stopListening();
      setIsVoice(false);
    }
    let messageToSend = message.trim() || transcriptTemp.current.trim();
    if (messageInput?.type) {
      //
    } else {
      messageToSend = messageInput;
    }
    if (messageToSend) {
      socket.emit("send_message1", {
        conversation_id: conversationId,
        message: messageToSend,
        user_id: userId,
        bot_id: botId,
      });
      setMessage("");
      setOpenBarOption(false);
      setShowSend(false);
    }
  };

  const handleReaction = async (reaction, message_id) => {
    if (message_id) {
      if (reaction === 1) {
        const element = document.getElementById(`like_${message_id}`);
        const svg = element.querySelector("svg");
        if (svg) {
          svg.style.fill = "#97a0af";
          svg.style.stroke = "#97a0af";
        }
        const elementOld = document.getElementById(`dislike_${message_id}`);
        const oldSvg = elementOld.querySelector("svg");
        if (oldSvg) {
          oldSvg.style.fill = "#ffffff";
          oldSvg.style.stroke = "#97a0af";
        }
      }
      if (reaction === 0) {
        const element = document.getElementById(`dislike_${message_id}`);
        const svg = element.querySelector("svg");
        if (svg) {
          svg.style.fill = "#97a0af";
          svg.style.stroke = "#97a0af";
        }

        const elementOld = document.getElementById(`like_${message_id}`);
        const oldSvg = elementOld.querySelector("svg");
        if (oldSvg) {
          oldSvg.style.fill = "#ffffff";
          oldSvg.style.stroke = "#97a0af";
        }
      }
      socket.emit("reaction", {
        conversation_id: conversationId,
        message_id: message_id,
        reaction: reaction,
        userId: userId,
      });
    }
  };

  const speakText = (text, index) => {
    setReadingMessageIndex(index);
    text = text
      .replaceAll("(", "")
      .replaceAll('"', "")
      .replaceAll(")", "")
      .replaceAll(".", ",")
      .replaceAll("!", ",")
      .replaceAll("AI", "ây ai")
      .replaceAll("DXBOT", "Đê Ích Bốt")
      .toLowerCase();

    if (window.responsiveVoice) {
      window.responsiveVoice.speak(
        text,
        "Vietnamese Female",
        { rate: 1 },
        () => {
          setReadingMessageIndex(null);
        }
      );

      setTimeout(() => {
        setReadingMessageIndex(null);
      }, text.length * 100);
    } else {
      console.error("responsiveVoice is not loaded.");
    }
  };

  const pauseSpeak = () => {
    if (window.responsiveVoice) {
      window.responsiveVoice.cancel();
      setReadingMessageIndex(null);
    } else {
      console.error("responsiveVoice is not loaded.");
    }
  };

  const handleWheel = (event) => {
    if (listRef.current) {
      listRef.current.scrollLeft += event.deltaY;
    }
  };

  const handleVoice = () => {
    setShowSend(true);
    setIsVoice((prev) => !prev);
    setElapsedTime(0);
    const newTimer = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);
    setTimer(newTimer);
    startListening();
  };

  const startListening = () => {
    if (recognitionRef.current && !isListening) {
      setIsListening(true);
      recognitionRef.current.start();
    }
  };

  const stopListening = () => {
    return new Promise((resolve) => {
      if (recognitionRef.current && isListening) {
        recognitionRef.current.onend = () => {
          setIsListening(false);
          resolve();
        };
        recognitionRef.current.stop();
      } else {
        resolve();
      }
    });
  };

  const minutes = Math.floor(elapsedTime / 60);
  const seconds = elapsedTime % 60;
  const totalDuration = 5 * 60;
  const progress = (elapsedTime / totalDuration) * 100;

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: -20, y: -85 });

  const handleStart = () => {
    setIsDragging(true);
  };

  const handleStop = (e, data) => {
    setPosition({ x: data.x, y: data.y });
    setIsDragging(false);
  };

  const handleClick = () => {
    if (!isDragging) {
      setShowChat(true);
    }
  };

  const handleCreateNewChat = () => {
    setConversationId(null);
    setMessage("");
    setMessages([]);
    setQuestionSuggest([]);
    setShowQuestionSuggest(true);
    socket.emit("create_chat", { user_id: userId, bot_id: botId });
  };

  return (
    <AppContainer>
      {!showChat && (
        <Draggable
          axis="both"
          position={position}
          onStart={handleStart}
          onStop={handleStop}
          handle=".handle"
        >
          <Bot>
            <IconBot id="c-icon-bot" onClick={handleClick} />
            <IconDrag className="handle" />
          </Bot>
        </Draggable>
      )}
      {showChat && (
        <Container _miniSize={miniSize} className="chatbot-root">
          <Header _miniSize={miniSize}>
            <p id="lable">DXBOT</p>
            <div id="action">
              <IconMiMx id="mimx" onClick={handleSize} />
              <IconClose id="close" onClick={handleShowChat} />
            </div>
          </Header>
          <Body>
            <NavBar _show={!miniSize && !readyChat}>
              {/* <div id="new-chat">
                <IconPlus />
                <p>Cuộc trò chuyện mới</p>
              </div> */}
              <ChatHistory>
                <NoLoginRender>
                  <IconDefaultHistory />
                  <p id="text">Vui lòng đăng nhập để trò chuyện với DXBOT</p>
                  <div id="button-login" onClick={() => setShowLogin(true)}>
                    <p id="text-login">Đăng nhập ngay</p>
                  </div>
                </NoLoginRender>
              </ChatHistory>
            </NavBar>
            <BodyContent>
              <StartConversation _display={conversationId === null}>
                <StartConversationGuide>
                  <StartConversationGuideChatHistory>
                    {!showLogin && !readyChat && (
                      <StartConversationGuideNoLoginRender>
                        <IconDefaultHistory />
                        <p id="text">
                          Vui lòng đăng nhập để trò chuyện với DXBOT
                        </p>
                        <div id="button-login">
                          <p id="text-login" onClick={() => setShowLogin(true)}>
                            Đăng nhập ngay
                          </p>
                        </div>
                      </StartConversationGuideNoLoginRender>
                    )}
                    {showLogin && (
                      <form
                        className="cbe_login_form"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="cbe_login_icon">
                          <IconDXBOT />
                        </div>
                        <p id="cbe_login_input_title">
                          Tài khoản <span id="cbe_login_error">*</span>
                        </p>
                        <input
                          className="cbe_login_input"
                          {...register("username", { required: true })}
                          placeholder="Tài khoản"
                        />
                        {errors.username && (
                          <span id="cbe_login_error">
                            Username là trường bắt buộc !
                          </span>
                        )}
                        <p id="cbe_login_input_title">
                          Mật khẩu <span id="cbe_login_error">*</span>
                        </p>
                        <input
                          className="cbe_login_input"
                          {...register("password", { required: true })}
                          placeholder="Mật khẩu"
                        />
                        {errors.password && (
                          <span id="cbe_login_error">
                            Mật khẩu là trường bắt buộc !
                          </span>
                        )}
                        <button type="submit" className="cbe_login_submit">
                          Đăng nhập
                        </button>
                      </form>
                    )}
                  </StartConversationGuideChatHistory>
                </StartConversationGuide>
              </StartConversation>
              <MessagesContainer>
                {htmlContent ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100vh",
                    }}
                  >
                    <Button onClick={() => setHtmlContent("")}>
                      <i class="fa-solid fa-xmark"></i>
                    </Button>
                    <iframe
                      ref={iframeRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                      title="Form from Socket"
                    />
                  </div>
                ) : (
                  messages.map((msg, index) => {
                    let avatar = <IconDf />;
                    let myMessage = false;
                    const type = msg.type ? msg.type : "text";

                    if (!msg.is_bot_message) {
                      switch (msg.user_id) {
                        case -2:
                          avatar = <IconDf />;
                          break;
                        default:
                          avatar = <IconCs />;
                          break;
                      }
                      if (msg?.user_id === userId) {
                        myMessage = true;
                      }
                    } else {
                      avatar = <IconBotAvatar />;
                    }

                    if (msg.type === "join") {
                      return <JoinMessage>{msg.message}</JoinMessage>;
                    }
                    return (
                      <Message key={index} _my_message={myMessage}>
                        {!myMessage && <Avatar>{avatar}</Avatar>}
                        <ContentContainer _my_message={myMessage}>
                          <Content _my_message={myMessage}>
                            {type === "text" && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: msg?.message,
                                }}
                              />
                            )}

                            {type === "open_form" && (
                              <p>
                                Vui lòng điền đầy đủ thông tin vào biểu mẫu sau
                                :{" "}
                                <i
                                  style={{
                                    textDecoration: "underline",
                                    color: "#5c5c5c",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setHtmlContent(msg.html_content)
                                  }
                                >
                                  {msg.html_content_name}
                                </i>
                              </p>
                            )}
                          </Content>
                          <Action _my_message={myMessage}>
                            <div onClick={() => speakText(msg?.message, index)}>
                              <IconVolumn />
                            </div>
                            <div
                              id={`like_${msg.message_id}`}
                              onClick={() => handleReaction(1, msg.message_id)}
                            >
                              <IconLike />
                            </div>
                            <div
                              id={`dislike_${msg.message_id}`}
                              onClick={() => handleReaction(0, msg.message_id)}
                            >
                              <IconDisLike />
                            </div>
                          </Action>
                        </ContentContainer>
                      </Message>
                    );
                  })
                )}

                {isLoading && (
                  <Message key="loading" _my_message={false}>
                    <IconBotAvatar />
                    <ContentContainer _my_message={false}>
                      <Content _my_message={false}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<div class="loading">
                              <div class="load1c">
                                <div class="load1"></div>
                              </div>
                              <div class="load2c">
                                <div class="load2"></div>
                              </div>
                              <div class="load3c">
                                <div class="load3"></div>
                              </div>
                            </div>`,
                          }}
                        />
                      </Content>
                    </ContentContainer>
                  </Message>
                )}
                <div ref={messagesEndRef} />
              </MessagesContainer>
            </BodyContent>
          </Body>
          {!htmlContent && readyChat && (
            <InputContainerWrapper>
              {!openBarOption &&
                showQuestionSuggest &&
                questionSuggest &&
                questionSuggest.length > 0 && (
                  <QuestionList ref={listRef} onWheel={handleWheel}>
                    {questionSuggest.map((item, index) => {
                      return (
                        <QuestionListItem
                          key={index}
                          _index={index}
                          onClick={() => handleSendMessage(item)}
                        >
                          {item}
                        </QuestionListItem>
                      );
                    })}
                  </QuestionList>
                )}

              <InputContainer>
                {openBarOption &&
                  questionSuggest &&
                  questionSuggest.length > 0 && (
                    <BarOption>
                      {questionSuggest.map((item, index) => {
                        return (
                          <BarOptionItem
                            key={index}
                            _index={index}
                            onClick={() => handleSendMessage(item)}
                          >
                            {item}
                          </BarOptionItem>
                        );
                      })}
                    </BarOption>
                  )}
                <InputBar onClick={() => setOpenBarOption(!openBarOption)}>
                  <IconBar id="bar" />
                </InputBar>
                {!isVoice ? (
                  <Input
                    type="text"
                    placeholder="Nhập câu hỏi của bạn..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage(e.target.value);
                      }
                    }}
                  />
                ) : (
                  <VoiceInput>
                    <VoicePause>
                      <i
                        className="fa-solid fa-circle-xmark"
                        style={{ color: "#8c8c8c" }}
                        onClick={() => {
                          setIsVoice(false);
                          clearInterval(timer);
                          setTimer(null);
                          setElapsedTime(0);
                        }}
                      ></i>
                    </VoicePause>
                    <VoiceProgress>
                      <VoicePause>
                        <i
                          className="fa-solid fa-circle-pause"
                          style={{ color: "#ffffff" }}
                          onClick={() => {
                            setIsVoice(false);
                            clearInterval(timer);
                            setTimer(null);
                            setElapsedTime(0);
                          }}
                        ></i>
                      </VoicePause>
                      <VoiceProgressRun progress={progress} />
                      <VoiceProgressTime>
                        <p>{`${minutes < 10 ? "0" : ""}${minutes}:${
                          seconds < 10 ? "0" : ""
                        }${seconds}`}</p>
                      </VoiceProgressTime>
                    </VoiceProgress>
                  </VoiceInput>
                )}
                <InputActionWrapper>
                  <ButtonVoice onClick={handleVoice} _show={!message}>
                    <IconMic />
                  </ButtonVoice>
                  <Button
                    _show={message || showSend}
                    onClick={handleSendMessage}
                    disabled={!isVoice && (!message || isWaiting)}
                  >
                    {!isVoice && (!message || isWaiting) ? (
                      <IconDisSend />
                    ) : (
                      <IconSend />
                    )}
                  </Button>
                </InputActionWrapper>
              </InputContainer>
            </InputContainerWrapper>
          )}
        </Container>
      )}
    </AppContainer>
  );
}

const AppContainer = styled.div`
  font-family: "Roboto", sans-serif;

  p {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => (props._miniSize ? "80vh" : "100vh")};
  width: ${(props) => (props._miniSize ? "30%" : "100%")};
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 1);
  z-index: 9999999;
  position: fixed;
  transition: width 1s ease-in-out, height 1s ease-in-out;

  @media (max-width: 767px) {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    padding: 10px;
    width: 100%;
    font-size: 14px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  background-color: #062235;
  display: flex;
  min-height: 56px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #5d5d5d;
  font-size: 18px;
  box-sizing: border-box;
  padding: 8px 16px;

  #lable {
    color: #ffffff;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
  }

  #action {
    display: flex;
    gap: 16px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    font-size: 16px;
  }
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 56px);
  flex-direction: row;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;

const NavBar = styled.div`
  background-color: #f4f5f7;
  display: ${(props) => (props._show ? "flex" : "none")};
  border-right: solid 1px #dfe1e6;
  gap: 24px;
  max-width: 264px;
  padding: 16px 0 16px 0;
  flex-direction: column;
  box-sizing: border-box;

  #new-chat {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 232px;
    max-height: 32px;
    height: 32px;

    background-color: #10ae4d;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 4px;
    gap: 8px;

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    img {
      width: 16px;
      height: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;
const ChatHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;

const NoLoginRender = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;

  #text {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-left: 24px;
    margin-right: 24px;
  }

  #button-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px 6px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 80px;
    height: 32px;
    border: solid 1px #062235;
    cursor: pointer;

    #text-login {
      color: #062235;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;
const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;
const MessagesContainer = styled.div`
  flex: 1;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    inline-size: 100%;
  }
`;

const JoinMessage = styled.p`
  text-align: center;
  color: #8d8d8d;
  font-size: 13px;
`;
const Message = styled.div`
  border-radius: 10px;
  align-self: ${(props) => props._my_message && "flex-end"};
  gap: 12px;
  display: flex;
  flex-direction: ${(props) => (props._my_message ? "row-reverse" : "row")};
  box-sizing: border-box;
  @media (max-width: 767px) {
    max-width: 90%;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    max-width: 80%;
  }
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props._my_message ? "end" : "start")};
  gap: 4px;
  position: relative;
  font-size: 14px;
  width: 100%;
  background-color: ${(props) => (props._my_message ? "#e7f7ed" : "#ffffff")};
  ${(props) =>
    props._my_message &&
    "padding: 12px 16px 12px 16px; border-radius: 8px; font-weight: 600; line-height: 20px;"};
`;
const Content = styled.div`
  color: #000000;
  display: flex;
  justify-content: ${(props) => (props._my_message ? "end" : "start")};
  box-sizing: border-box;
  width: 100%;

  img {
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
  }

  div {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #02b102;
    font-weight: 600;
  }

  p {
    white-space: pre-line;
    font-size: 14px;
    line-height: 20px;
    text-align: start;
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }

  .loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .load1c {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .load1 {
    background: linear-gradient(
      to right,
      rgb(230, 230, 230),
      rgb(185, 185, 186),
      rgb(230, 230, 230)
    );
    height: 100%;
    width: 0%;
    animation: load 1s linear infinite;
  }

  .load2c {
    width: 70% !important;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .load2 {
    background: linear-gradient(
      to right,
      rgb(230, 230, 230),
      rgb(185, 185, 186),
      rgb(230, 230, 230)
    );
    height: 100%;
    width: 0%;
    animation: load 1s linear infinite;
  }

  .load3c {
    width: 50% !important;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .load3 {
    background: linear-gradient(
      to right,
      rgb(230, 230, 230),
      rgb(185, 185, 186),
      rgb(230, 230, 230)
    );
    height: 100%;
    width: 0%;
    animation: load 1s linear infinite;
  }
  @keyframes load {
    0% {
      width: 0%;
      transform: scaleY(1); /* Hiệu ứng phồng */
    }
    50% {
      transform: scaleY(1.1); /* Tăng kích thước tại giữa */
    }
    100% {
      width: 100%;
      transform: scaleY(1); /* Quay lại kích thước ban đầu */
    }
  }
`;
const Action = styled.div`
  display: ${(props) => (props._my_message ? "none" : "flex")};
  flex-direction: row;
  gap: 8px;
`;
const InputContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  box-sizing: border-box;
  border-top: solid 1px #dfe1e6;
  background-color: #f4f5f7;
`;

const QuestionList = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  max-height: 24px;
  height: 24px;
  gap: 11px;
  cursor: pointer;

  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    width: 100%;
  }
`;

const QuestionListItem = styled.div`
  border: solid 1px #e5e5e5;
  border-radius: 20px;
  font-size: 12px;
  box-sizing: border-box;
  height: 100%;
  padding: 4px 8px 4px 8px;
  font-weight: 500;
  line-height: 16px;
  color: #484848;

  &:hover {
    background-color: #f9f9f9;
  }

  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease forwards;
  animation-delay: ${(props) => props._index * 0.1}s;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 48px;
  height: 48px;
  padding: 5px;
  border-radius: 8px;
  align-items: center;
  background-color: #ffffff;
  justify-content: start;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    height: 50px;
  }
`;
const InputBar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
`;
const BarOption = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  box-sizing: border-box;
`;
const BarOptionItem = styled.div`
  padding: 10px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 13px;
  /* background-color: #cdcdcd; */
  border: solid 1px #bfbfbf;
  cursor: pointer;

  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: ${(props) => props._index * 0.1}s;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
const Input = styled.input`
  flex: 1;
  border: none;
  font-size: 14px;
  background-color: #ffffff;
  box-sizing: border-box;
  color: black;
  &:focus {
    outline: none;
    border-color: #888888;
  }

  @media (max-width: 767px) {
    width: 80%;
    padding: 8px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    width: 80%;
    padding: 9px;
  }
`;

const VoiceInput = styled.div`
  flex: 1;
  border-radius: 30px;
  margin-right: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 60%;
    padding: 4px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    width: 65%;
    padding: 5px;
  }
`;

const VoicePause = styled.div`
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 50%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    font-size: 26px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    width: 19px;
    height: 19px;
    font-size: 28px;
  }
`;

const VoiceProgress = styled.div`
  flex: 1;
  border: 1px solid #a8a8a8;
  border-radius: 30px;
  display: flex;
  background-color: #8c8c8c;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    border-radius: 20px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    border-radius: 25px;
  }
`;

const VoiceProgressTime = styled.div`
  margin: 0 5px 0 0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  @media (max-width: 767px) {
    width: 45px;
    font-size: 12px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    width: 48px;
    font-size: 13px;
  }
`;
const InputActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
`;

const Button = styled.div`
  display: ${(props) => (props._show ? "block" : "none")};
  cursor: pointer;
  @media (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;

const ButtonVoice = styled.div`
  display: ${(props) => (props._show ? "block" : "none")};
  color: #062235;
  cursor: pointer;
  @media (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;

const Avatar = styled.div`
  display: ${(props) => (props._my_message ? "none" : "flex")};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    width: 28px;
    height: 28px;
  }
`;
const Bot = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  max-height: 139px;

  #c-icon-bot {
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.3));
    transition: box-shadow 0.3s ease;
  }

  #c-icon-bot:hover {
    filter: drop-shadow(6px 6px 10px rgba(0, 0, 0, 0.3));
  }

  .handle {
    position: absolute;
  }

  cursor: pointer;

  @media (max-width: 767px) {
    img {
      width: 100px;
      height: 100px;
    }
  }
  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;
const StartConversation = styled.div`
  box-sizing: border-box;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  gap: 16px;
  display: ${(props) => (props._display ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    inline-size: 100%;
  }

  #new-chat {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 232px;
    max-height: 32px;
    height: 32px;
    background-color: #062235;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 4px;
    gap: 8px;
    color: white;
  }
`;
const StartConversationGuide = styled.div`
  background-color: rgb(255, 255, 255);
  display: ${(props) => (props._miniSize ? "none" : "flex")};
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 16px 0 16px 0;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  box-sizing: border-box;

  #new-chat {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 232px;
    max-height: 32px;
    height: 32px;
    background-color: #062235;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 4px;
    gap: 8px;

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    img {
      width: 16px;
      height: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;
const StartConversationGuideChatHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .cbe_login_form {
    display: flex;
    flex-direction: column;
    background-color: #f4f5f7;
    color: #062235;
    justify-content: start;
    width: 65%;
    height: fit-content;
    min-height: 80%;
    gap: 16px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;

    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    #cbe_login_error {
      color: darkred;
      font-size: 12px;
    }

    #cbe_login_input_title {
      font-size: 14px;
    }

    .cbe_login_icon {
      align-self: center;
    }

    .cbe_login_input {
      width: 100%;
      padding: 10px 15px;
      font-size: 14px;
      border: 2px solid #ccc;
      border-radius: 5px;
      outline: none;
      color: #595959;
      transition: all 0.3s ease;
      box-sizing: border-box;

      &:focus {
        border-color: #062235;
        box-shadow: 0 0 5px rgba(3, 69, 140, 0.5);
      }

      &::placeholder {
        color: #aaa;
        font-style: italic;
        font-size: 12px;
      }
    }

    .cbe_login_submit {
      padding: 10px 20px;
      font-size: 14px;
      color: white;
      background-color: #062235;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #0a3a5a;
        box-shadow: 0 4px 6px rgba(2, 49, 99, 0.3);
      }

      &:active {
        background-color: #062235;
        transform: scale(0.98);
      }
    }
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;

const StartConversationGuideNoLoginRender = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;

  #text {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-left: 24px;
    margin-right: 24px;
  }

  #button-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px 6px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 80px;
    height: 32px;
    border: solid 1px #062235;
    cursor: pointer;

    #text-login {
      color: #062235;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1124px) {
  }
`;
export default App;
